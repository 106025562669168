@font-face {
  font-family: "Museo-Sans";
  src: url("fonts/MuseoSans-300.otf") format("truetype");
}
body {
  font-family: "Museo-Sans";
}

h1 {
  font-size: 1.25rem;
  font-weight: 400;
}

h2 {
  font-size: 1rem;
  font-weight: 200;
}

h3 {
  font-size: 1.25rem;
  font-weight: 400;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.25rem;
}

p {
  font-size: 1rem;
}

a {
  font-size: 1rem;
}

@media (min-width: 480px) {
  h1 {
    font-size: 1.5rem;
    font-weight: 400;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 200;
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 1.75rem;
    font-weight: 400;
  }

  h2 {
    font-size: 1.75rem;
    font-weight: 200;
  }
}
body,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.k-drawer-container {
  background-color: #D4D6E4;
}
.k-drawer-container .k-drawer {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.k-drawer-container .k-drawer.k-drawer-start {
  border-right-width: 0;
}
.k-drawer-container.k-drawer-push .k-drawer {
  position: sticky;
  top: 80px;
  height: calc(100vh - 80px);
}
.k-drawer-container .k-drawer-item {
  user-select: none;
}
.k-drawer-container .k-drawer-content {
  overflow: hidden;
}

.card-container {
  background-color: #FFFFFF;
  padding: 40px 0;
  text-align: center;
}
.card-container .card-header-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.card-container.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 10px;
  row-gap: 1em;
}
.card-container .card-buttons .k-button {
  min-width: 105px;
}
.card-container .card-ranges .k-dateinput {
  width: 105px;
}
.card-container .chart-tooltip {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}
.card-container .card-ranges .k-i-calendar {
  color: #656565;
  cursor: pointer;
  margin-right: 5px;
}
.card-container .card-ranges {
  white-space: nowrap;
}
.card-container .card-ranges .k-daterangepicker .k-textbox-container,
.card-container .card-ranges .k-daterangepicker .k-floating-label-container {
  padding-top: 0;
  min-width: 105px;
}
.card-container .card-ranges .k-daterangepicker .k-label {
  display: none;
}
.card-container .card-component {
  grid-column: 1/-1;
  overflow: hidden;
}

@media (min-width: 480px) {
  .card-container {
    text-align: initial;
    padding: 30px;
  }
  .card-container.grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .card-container .card-title {
    grid-column: 1/2;
    grid-row: 1;
  }
  .card-container .card-buttons {
    text-align: center;
    grid-column: 1/-1;
    grid-row: 2;
  }
  .card-container .card-ranges {
    text-align: right;
    grid-column: 2/3;
    grid-row: 1;
  }
}
@media (min-width: 768px) {
  .card-container {
    margin: 25px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .card-container.grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .card-container .card-buttons {
    text-align: center;
  }
  .card-container .card-ranges {
    text-align: right;
    grid-column: 3/4;
  }
  .card-container .card-component {
    grid-column-start: 1;
    grid-column-end: -1;
  }
}
@media (min-width: 900px) {
  .card-container .card-buttons {
    text-align: center;
    grid-column: 2/3;
    grid-row: 1;
  }
}
@media (max-width: 900px) {
  .card-header-wrapper {
    flex-direction: column;
  }
  .card-header-wrapper .card-buttons,
.card-header-wrapper .card-ranges {
    margin-top: 1rem;
    text-align: left;
  }
}
.header-bg {
  background-image: url("./../assets/header-bg.png");
}

.header {
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 3;
}
.header .nav-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: #FFFFFF;
}
.header .menu-button {
  flex: 0 0 50px;
  text-align: center;
}
.header .hamburger-icon {
  background-image: url("./../assets/hamburger-icon.svg");
  background-repeat: no-repeat;
  width: 20px;
  cursor: pointer;
}
.header .title {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 25px;
}
.header .k-avatar {
  display: block;
  width: 40px;
  height: 40px;
  flex-basis: 40px;
  margin: 0 25px;
  box-shadow: 0 0 0px 1px #FFFFFF;
}
.header .settings {
  display: none;
}

@media (min-width: 480px) {
  .header .title {
    flex-direction: row;
    align-items: center;
  }
  .header .vl {
    border-left: 1px solid #FFFFFF;
    height: 25px;
    margin: 6px 15px;
  }
}
@media (min-width: 768px) {
  .header .settings {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
  }
  .header .k-dropdown {
    max-width: 90px;
    margin-left: 15px;
  }
}
.dashboard-page .k-grid-toolbar .k-textbox {
  margin-bottom: 10px;
}

@media (min-width: 480px) {
  .dashboard-page .k-grid-toolbar .k-textbox {
    margin-bottom: 0;
    float: left;
  }
  .dashboard-page .k-grid-toolbar .k-button {
    float: right;
  }
}
@media (min-width: 768px) {
  .dashboard-page .card-container:last-of-type {
    padding-top: 30px;
  }
}
.planning-page .card-container,
.planning-page .personal-card {
  grid-template-columns: repeat(1, 1fr);
}
.planning-page .personal-card {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 1em;
  row-gap: 1em;
}
.planning-page .k-card-header {
  padding-left: 10px;
  padding-right: 10px;
}
.planning-page .card-title {
  grid-column: 1/-1;
}
.planning-page .k-hbox {
  padding: 0 20px;
  background: transparent;
  align-items: center;
}
.planning-page .k-card-title {
  font-size: 15px;
  margin-bottom: -6px;
  text-align: left;
}
.planning-page .k-card-subtitle {
  margin-top: 0;
  text-transform: uppercase;
  font-size: 11px;
}
.planning-page .disabled {
  opacity: 0.5;
}

@media (min-width: 480px) {
  .planning-page .card-container,
.planning-page .personal-card {
    grid-template-columns: repeat(2, 1fr);
  }
  .planning-page .k-hbox {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .planning-page .k-hbox {
    border-width: 0;
  }
}
@media (min-width: 960px) {
  .planning-page .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .planning-page .personal-card {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1130px) {
  .planning-page .personal-card {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1360px) {
  .planning-page .card-container {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1770px) {
  .planning-page .personal-card {
    grid-template-columns: repeat(5, 1fr);
  }
}
.profile-page .card-container {
  text-align: left;
}
.profile-page .k-form .k-form-field {
  display: block;
  padding: 0 15px;
  margin-top: 1.5rem;
}
.profile-page .k-form .k-label {
  text-align: right;
}
.profile-page .k-form-field:first-of-type .k-form-field-wrap {
  margin: auto;
}
.profile-page .k-form-field:first-of-type .k-avatar {
  margin: 0 auto 1rem;
}
.profile-page hr {
  margin-top: 2rem;
  opacity: 0.2;
}
.profile-page .k-form .k-form-buttons {
  justify-content: center;
}
.profile-page .k-form .k-form-buttons > * {
  min-width: 140px;
}

@media (min-width: 480px) {
  .profile-page .k-form .k-form-field {
    display: flex;
  }
  .profile-page .k-form-field:first-of-type .k-avatar {
    margin: 0;
  }
}
@media (min-width: 768px) {
  .profile-page .k-form {
    margin: 0 auto;
    padding: 0;
  }
  .profile-page .k-form .k-form-field {
    padding: 0;
  }
}
.info-page {
  background-color: #06163e;
  background-image: url("../assets/info-bg.jpg");
  background-position: right 0px top 0px;
  color: #FFFFFF;
  background-repeat: no-repeat;
  opacity: 0.9;
}
.info-page h1,
.info-page h2,
.info-page h3,
.info-page .component-link {
  font-family: Metric, Helvetica, Arial, sans-serif;
}
.info-page a:not(.k-button) {
  color: #71A6FF;
}
.info-page .content {
  padding-top: 40px;
  margin: 0 auto;
  position: relative;
  max-width: 1040px;
}
.info-page .section-1 {
  text-align: center;
}
.info-page .section-1 h1 {
  font-size: 48px;
  font-weight: bold;
}
.info-page .section-1 h2 {
  font-size: 30px;
  margin-bottom: 53px;
}
.info-page .section-1 .button-group {
  margin-bottom: 30px;
}
.info-page .section-1 .button-group .k-button {
  display: block;
  margin: 10px auto;
  min-width: 145px;
  width: 60%;
}
.info-page .section-1 .github-link {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}
.info-page .section-1 .github-link .github-text {
  margin-left: 15px;
}
.info-page .section-2 {
  text-align: center;
  margin-top: 40px;
  padding: 0 15px;
}
.info-page .section-3 {
  column-count: 2;
  margin-top: 40px;
  padding: 0 15px;
  text-align: center;
}
.info-page .section-3 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.info-page .package-item {
  display: inline-block;
  margin-top: 20px;
}
.info-page .package-title a {
  font-size: 17px;
  color: #cccccc;
  text-transform: uppercase;
}
.info-page .component-link {
  padding: 5px 0;
}
.info-page .component-link a {
  font-size: 20px;
}
.info-page .footer {
  background-image: url("../assets/info-waves.svg");
  background-repeat: no-repeat;
  height: 320px;
  background-size: cover;
}

@media (min-width: 480px) {
  .info-page .content {
    padding: 40px 40px 0;
  }
  .info-page .section-1 h1 {
    font-size: 71px;
  }
  .info-page .section-1 h2 {
    font-size: 38px;
  }
  .info-page .section-1 .button-group .k-button {
    display: inline-block;
    margin-right: 10px;
    width: auto;
  }
}
@media (min-width: 992px) {
  .info-page .section-3 {
    column-count: 3;
  }
}
@media (min-width: 1370px) {
  .info-page .content {
    padding: 120px 40px 0;
  }
  .info-page .section-1,
.info-page .section-2 {
    text-align: left;
    padding: 0;
  }
  .info-page .section-3 {
    text-align: left;
    column-count: 4;
    padding: 0;
  }
}
@media all and (-ms-high-contrast: none) {
  .k-drawer-container.k-drawer-push .k-drawer {
    position: static;
    height: auto;
  }

  .planning-page .card-title {
    margin-bottom: 1rem;
    text-align: left;
  }
  .planning-page .personal-card {
    display: block;
    margin-bottom: 1rem;
  }
}
.customer-photo {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-size: 32px 35px;
  background-position: center center;
  vertical-align: middle;
  line-height: 32px;
  box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, 0.2);
  margin-left: 5px;
}

.customer-name {
  display: inline-block;
  vertical-align: middle;
  line-height: 32px;
  padding-left: 10px;
}

.red {
  color: #d9534f;
}

.text-bold {
  font-weight: 600;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.chart-tooltip {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.employee-photo {
  display: inline-block;
  border-radius: 50%;
  background-size: 38px 41px;
  background-position: center center;
  vertical-align: middle;
  line-height: 32px;
}

.card-opacity {
  opacity: 0.5;
}

kendo-recurrence-frequency-editor .k-edit-label {
  width: unset;
  margin-right: 10px;
}

kendo-recurrence-frequency-editor .k-edit-field {
  width: unset;
  float: none;
}

.k-team-mark {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.material-bg .k-input,
.material-bg .k-dropdown-wrap .k-i-arrow-s {
  color: white;
}

.material-bg .k-dropdown-wrap {
  border-color: white;
}

.material-bg .k-dropdown-wrap.k-state-focused {
  border-color: #697bcf;
  border-bottom-width: 2px;
}

.material-bg .k-dropdown-wrap:hover {
  border-color: #2942bb;
  border-bottom-width: 2px;
}

.ddl-theme {
  width: 60px;
}

.ddl-locale .k-dropdown-wrap {
  width: 98px;
}